import * as React from "react"
import SharedHeader from "../components/general/components/SharedHeader"
import TransactionPage from "../components/robot/pages/TransactionPage"


// markup
const Transaction = () => {
  return (
    <React.Fragment>
      <SharedHeader />
      <TransactionPage />
    </React.Fragment>
  )
}

export default Transaction
