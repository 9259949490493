import {
  Button,
  Input,
  message,
  Pagination,
  Select,
  Skeleton,
  Spin,
  Tooltip,
} from "antd";
import { cloneDeep, isArray, isEmpty, map, set } from "lodash";
import get from "lodash.get";
import moment from "moment";
import moment2 from "moment-timezone";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { not_foundIcon } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getProfiles from "../../../newApi/profile/getProfiles";
import getProfileRobotProfitSummary from "../../../newApi/robots/getProfileRobotProfitSummary";
import getProfileRobotProfitSummary2 from "../../../newApi/robots/getProfileRobotProfitSummary2";
import getProfileTotalRobotProfit from "../../../newApi/robots/getProfileTotalRobotProfit";
import getRobotTransactions from "../../../newApi/robots/getRobotTransactions";
import getRobotTransactions2 from "../../../newApi/robots/getRobotTransactions2";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { formatDecimalNumber } from "../../../utilities/common-function";
import { robotStrategy, robotStrategyDataKey } from "../../../utilities/data";
import {
  convertVsdtTranslation,
  convertNumberToDateCharacter,
} from "../../../utilities/profile";
import {
  numberDecimal,
  timeZone,
  tooltipTextColor,
} from "../../../utilities/startUp";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import CoinProfitDetailsDrawer from "../components/CoinProfitDetailsDrawer";
import DailyProfitReportDrawer from "../components/DailyProfitReportDrawer";
import { getFormatedDate } from "../config";
import { InfoCircleOutlined } from "@ant-design/icons";

const PAGESIZE = 10;

// markup
let timeoutFunc;
const RobotPage = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  // const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [arrayLoading, setArrayLoading] = useState(true);
  const [searchSymbol, setSearchSymbol] = useState("");
  const [productRecordSelected, setProductRecordSelected] = useState({
    // 0 - total, 1 - month
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  });
  const [productCoinSelected, setProductCoinSelected] = useState({
    // 0 - today, 1 - total
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  });
  const [productRecordPagination, setProductRecordPagination] = useState({
    1: {
      0: {
        page: 1,
        total: 0,
        data: [],
      },
      1: {
        page: 1,
        total: 0,
        data: [],
      },
    },
    2: {
      0: {
        page: 1,
        total: 0,
        data: [],
      },
      1: {
        page: 1,
        total: 0,
        data: [],
      },
    },
    3: {
      0: {
        page: 1,
        total: 0,
        data: [],
      },
      1: {
        page: 1,
        total: 0,
        data: [],
      },
    },
    4: {
      0: {
        page: 1,
        total: 0,
        data: [],
      },
      1: {
        page: 1,
        total: 0,
        data: [],
      },
    },
  });
  const [productCoinPagination, setProductCoinPagination] = useState({
    1: {
      0: {
        page: 1,
        total: 0,
        data: [],
      },
      1: {
        page: 1,
        total: 0,
        data: [],
      },
    },
    2: {
      0: {
        page: 1,
        total: 0,
        data: [],
      },
      1: {
        page: 1,
        total: 0,
        data: [],
      },
    },
    3: {
      0: {
        page: 1,
        total: 0,
        data: [],
      },
      1: {
        page: 1,
        total: 0,
        data: [],
      },
    },
    4: {
      0: {
        page: 1,
        total: 0,
        data: [],
      },
      1: {
        page: 1,
        total: 0,
        data: [],
      },
    },
  });
  const [tabKey, setTabKey] = useState("2");
  const [totalProfit, setTotalProfit] = useState(null);
  const [todayProfit, setTodayProfit] = useState(null);
  const [netProfit, setNetProfit] = useState(0);
  const [productProfit, setProductProfit] = useState({});
  const [showRecord, setShowRecord] = useState(true);
  const [codeType, setCodeType] = useState("all");
  const [robotType, setRobotType] = useState(robotStrategyDataKey.usdt);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [coinDrawerVisible, setCoinDrawerVisible] = useState(false);
  const [filterList, setFilterList] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [robotProfiles, setRobotProfiles] = useState([]);
  const [profile, setProfile] = useState({});
  // let profile = get(props.user, "user.profile");

  const recordProfitType = [
    {
      title: t("today", sourceKey.pairing),
      value: 0,
    },
    {
      title: t("daily", sourceKey.pairing),
      value: 1,
    },
    {
      title: t("weekly", sourceKey.pairing),
      value: 3,
    },
    {
      title: t("monthly", sourceKey.pairing),
      value: 2,
    },
  ];

  const coinProfitType = [
    {
      title: t("today", sourceKey.pairing),
      value: 0,
    },
    {
      title: t("daily", sourceKey.pairing),
      value: 1,
    },
    {
      title: t("weekly", sourceKey.pairing),
      value: 3,
    },
    {
      title: t("monthly", sourceKey.pairing),
      value: 2,
    },
    {
      title: t("total", sourceKey.pairing),
      value: 4,
    },
  ];

  useEffect(() => {
    getProfileAPI();
    // getSummaryReport();
  }, []);

  useEffect(() => {
    // getTotalProfitAPI();
    // getData();
    // getSummaryReport();
  }, [robotProfiles]);

  // useEffect(() => {
  //   switch (tabKey) {
  //     case "2":
  //       switch (codeType) {
  //         case strategyCode.highFrequency:
  //         case strategyCode.highFrequency3:
  //         case strategyCode.highFrequency4:
  //           getSummaryReport(tabKey, codeType);
  //           break;
  //         default:
  //           getSummaryReport(tabKey, "HF");
  //           break;
  //       }
  //       break;

  //     default:
  //       break;
  //   }
  // }, [tabKey, codeType]);

  function getProfileAPI() {
    if (get(props.user, "user.profile._id")) {
      getProfiles(1, 0, {
        _id: get(props.user, "user.profile._id"),
      }).then((res) => {
        let profileData = get(res, "data[0]");
        let robotProfilesData = get(profileData, "robotProfiles");
        robotProfilesData = map(robotProfilesData, (item, index) => {
          return get(item, "robotProfileId");
        });
        // console.log(robotProfilesData);
        setProfile(profileData);
        setRobotProfiles(robotProfilesData);
      });
    }
  }

  function getSummaryReport(tab, code) {
    let extraFilter = {};
    if (code) {
      extraFilter.strategyCode = code;
    }
    // getProfileRobotProfitReport(1, 0, {
    //   profileId: get(props.user, "user.profile._id"),
    //   type: "summary",
    //   ...extraFilter,
    // })
    //   .then((res) => {
    //     switch (tab) {
    //       case "2":
    //         let newProductProfit = cloneDeep(productProfit);
    //         set(newProductProfit, "2", res);
    //         setProductProfit(newProductProfit);
    //         break;

    //       default:
    //         setTotalProfit(res);
    //         break;
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  useEffect(() => {
    // getData();
    setArrayLoading(true);
    const timeoutId = setTimeout(() => {
      getData();
      getTotalProfitAPI();
    }, 500);
    // console.log("timeoutId", timeoutId);
    return () => clearTimeout(timeoutId);

    // getData();
  }, [
    productCoinSelected,
    productRecordSelected,
    tabKey,
    showRecord,
    codeType,
    robotProfiles,
    robotType,
    searchSymbol,
  ]);

  function getData(skip = 0) {
    setArrayLoading(true);

    if (get(props.user, "user.profile._id") && !isEmpty(robotProfiles)) {
      let today = false;
      let filterGroup = {};

      if (!showRecord) {
        /// for coin record
        if (!isEmpty(searchSymbol)) {
          filterGroup.symbol = searchSymbol;
        }
        // filterGroup.type = "coin";
        switch (get(productCoinSelected, tabKey)) {
          case 0:
            today = true;
            filterGroup.timestampMin = moment2()
              .tz(timeZone)
              .startOf("D")
              .format();
            filterGroup.timestampMax = moment2()
              .tz(timeZone)
              .endOf("D")
              .format();
            filterGroup.sort = {
              timestamp: -1,
              totalOriginProfit: -1,
            };
            break;
          case 1:
            filterGroup.type = "day";
            filterGroup.sort = {
              timestamp: -1,
            };
            break;
          case 2:
            filterGroup.type = "month";
            filterGroup.sort = {
              profitDate: -1,
            };
            break;
          case 3:
            filterGroup.type = "week";
            filterGroup.sort = {
              profitDate: -1,
            };
            break;
          default:
            break;
        }
        // if (get(productCoinSelected, tabKey) === 0) {
        // }
      } else {
        /// for user record
        switch (get(productRecordSelected, tabKey)) {
          case 0: /// today record fliter
            today = true;
            filterGroup.timestampMin = moment2()
              .tz(timeZone)
              .startOf("D")
              .format();
            filterGroup.timestampMax = moment2()
              .tz(timeZone)
              .endOf("D")
              .format();
            filterGroup.sort = {
              timestamp: -1,
              "sellInfo.totalProfit": 1,
            };
            break;
          case 1: // daily filter
            filterGroup.type = "day";
            filterGroup.sort = {
              timestamp: -1,
            };
            break;
          case 2: /// monthly filter
            filterGroup.type = "month";
            filterGroup.sort = {
              monthYear: -1,
            };
            break;
          case 3: /// weekly filter
            filterGroup.type = "week";
            filterGroup.sort = {
              profitDate: -1,
            };
            break;

          default:
            break;
        }
      }

      clearTimeout(timeoutFunc);

      timeoutFunc = setTimeout(() => {
        getRobotTransactions2(PAGESIZE, skip, {
          profileIdIn: robotProfiles,
          ...filterGroup,
          showRecord,
          robotType,
          today: today,
          liquidation: 0,
          ignoreProfitNe: 1,
        })
          .then((res) => {
            // console.log("res", res);
            let newData = cloneDeep(productCoinPagination);
            set(
              newData,
              `${tabKey}.${get(
                showRecord === false
                  ? productCoinSelected
                  : productRecordSelected,
                tabKey
              )}.data`,
              get(res, "data")
            );
            set(
              newData,
              `${tabKey}.${get(
                showRecord === false
                  ? productCoinSelected
                  : productRecordSelected,
                tabKey
              )}.total`,
              get(res, "total")
            );
            set(
              newData,
              `${tabKey}.${get(
                showRecord === false
                  ? productCoinSelected
                  : productRecordSelected,
                tabKey
              )}.page`,
              skip / PAGESIZE + 1
            );
            // console.log(newData);
            if (!showRecord) {
              setProductCoinPagination(newData);
            } else {
              setProductRecordPagination(newData);
            }

            setFilterList(filterGroup);

            setArrayLoading(false);
          })
          .catch((err) => {
            setArrayLoading(false);
            // console.log(err);
            message.error(err?.message);
          });
      }, 1000);
    } else {
      setArrayLoading(false);
    }
  }

  function getTotalProfitAPI() {
    // console.log("getTotalProfit", robotProfiles);
    if (!isEmpty(robotProfiles)) {
      // console.log("totalprofit", robotProfiles);
      getProfileRobotProfitSummary("all", 0, {
        profileIdIn: robotProfiles,
        robotType: robotType,
        liquidation: 0,
        ignoreProfitNe: 1,
      })
        .then((res) => {
          // console.log("total", res);
          // console.log("totalProfit", get(res, "totalProfit"));
          setTotalProfit(get(res, "totalProfit"));
        })
        .catch((err) => {
          // console.log(err);
        });

      let filterGroup = {};
      // filterGroup.timestampMin = moment().startOf("D").toDate();
      // filterGroup.timestampMax = moment().endOf("D").toDate();
      filterGroup.timestampMin = moment2().tz(timeZone).startOf("D").format();
      filterGroup.timestampMax = moment2().tz(timeZone).endOf("D").format();

      getProfileRobotProfitSummary(1, 0, {
        profileIdIn: robotProfiles,
        ...filterGroup,
        robotType,
        liquidation: 0,
        ignoreProfitNe: 1,
      })
        .then((res) => {
          // console.log("today", res);
          // console.log("todayProfit", get(res, "totalProfit"));
          setTodayProfit(get(res, "totalProfit"));
        })
        .catch((err) => {
          // console.log(err);
        });

      getProfileRobotProfitSummary("all", 0, {
        profileIdIn: robotProfiles,
        robotType: robotType,
        ignoreProfitNe: 1,
      })
        .then((res) => {
          // console.log("net total", res);
          // console.log("totalProfit", get(res, "totalProfit"));
          setNetProfit(get(res, "totalProfit"));
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }
  // console.log(productRecordSelected);
  const _renderRecordList = (tab, selectedType, productRecordPagination) => {
    let isShowDay = selectedType == 0;
    let relatedPagination =
      get(productRecordPagination, `${tab}.${selectedType}`) || {};
    let relatedData = get(relatedPagination, `data`) || [];
    // console.log("related data", relatedData);
    return (
      <>
        {/* <div className="mx-2 mt-2">{_renderRobotType()}</div> */}
        <div className="   mx-2">
          <div className="flex justify-between mx-1">
            <div>{_renderRecordType()}</div>
            <div
              className="mt-4"
              // style={{
              //   width: "100px",
              // }}
            >
              <Select
                className=""
                defaultValue={productRecordSelected[tab]}
                value={productRecordSelected[tab]}
                onChange={(e) => {
                  setProductRecordSelected({
                    ...productRecordSelected,
                    [tab]: e,
                  });
                }}
              >
                {map(recordProfitType, (item, index) => {
                  return (
                    <Select.Option
                      value={item?.value}
                      style={{
                        width: "100px",
                      }}
                    >
                      {item.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>

          {/* <div className="">
            <div className="flex flex-wrap justify-around  mt-2 mb-4">
              <span className="inline-block mr-2 mt-2">
                <Button
                  shape="round"
                  className={`p-2 ${
                    isShowDay ? "main-button-color" : "second-button-color"
                  }`}
                  onClick={(e) => {
                    setProductRecordSelected({
                      ...productRecordSelected,
                      [tab]: 0,
                    });
                  }}
                >
                  {t("today", sourceKey.pairing)}
                </Button>
              </span>
              <span className="inline-block mr-2 mt-2">
                <Button
                  shape="round"
                  className={`p-2 ${
                    !isShowDay && productRecordSelected[tab] === 1
                      ? "main-button-color"
                      : "second-button-color"
                  }`}
                  onClick={(e) => {
                    setProductRecordSelected({
                      ...productRecordSelected,
                      [tab]: 1,
                    });
                  }}
                >
                  {t("daily", sourceKey.pairing)}
                </Button>
              </span>
              <span className="inline-block mr-2 mt-2">
                <Button
                  shape="round"
                  className={`p-2 ${
                    !isShowDay && productRecordSelected[tab] === 3
                      ? "main-button-color"
                      : "second-button-color"
                  }`}
                  onClick={(e) => {
                    setProductRecordSelected({
                      ...productRecordSelected,
                      [tab]: 3,
                    });
                  }}
                >
                  {t("weekly", sourceKey.highFrequency)}
                </Button>
              </span>
              <span className="inline-block mt-2 ">
                <Button
                  shape="round"
                  className={`p-2 ${
                    !isShowDay && productRecordSelected[tab] === 2
                      ? "main-button-color"
                      : "second-button-color"
                  }`}
                  onClick={(e) => {
                    setProductRecordSelected({
                      ...productRecordSelected,
                      [tab]: 2,
                    });
                  }}
                >
                  {t("monthly", sourceKey.highFrequency)}
                </Button>
              </span>
            </div>
          </div> */}
        </div>
        <div className="rounded-xl lightgreybox-bg-color  mx-1 py-1 px-2 mb-5 ">
          {/* <span className="inline-block text-sm font-semibold">
            {isShowDay
              ? t("profitToday", sourceKey.pairing)
              : t("totalProfit", sourceKey.pairing)}
          </span> */}
          {arrayLoading ? (
            <>
              <Skeleton active rows={5}></Skeleton>
              <Skeleton active rows={5}></Skeleton>
              <Skeleton active rows={5}></Skeleton>
              <Skeleton active rows={5}></Skeleton>
              <Skeleton active rows={5}></Skeleton>
              <Skeleton active rows={5}></Skeleton>
            </>
          ) : isArray(relatedData) && !isEmpty(relatedData) ? (
            <React.Fragment>
              {map(relatedData, (item, index) => {
                let profileTransaction = item?.profileTransaction || [];
                let freeTransaction = false;
                if (profileTransaction.length > 0) {
                  freeTransaction = true;
                }

                let title = "";
                if (!isShowDay) {
                  switch (productRecordSelected[tab]) {
                    case 1: // daily
                      title = moment(get(item, "date")).format("DD/MM/YYYY");
                      break;
                    case 2: // monthly
                      title = get(item, "monthYear");
                      break;
                    case 3: // weekly
                      title = get(item, "dateStr");
                      break;

                    default:
                      break;
                  }
                }

                return isShowDay ? (
                  <>
                    <div
                      className=" p-2  border-dotted border-b-2  "
                      key={index}
                      style={{ backgroundColor: "#20262F" }}
                    >
                      <div className="flex justify-between">
                        <div className="flex items-center">
                          {get(item, "populatedRobotId.symbol") +
                            "-" +
                            `${
                              get(item, "populatedRobotId._id")
                                ? `${get(
                                    item,
                                    "populatedRobotId._id"
                                  ).substring(
                                    get(item, "populatedRobotId._id").length - 4
                                  )}`
                                : ""
                            } ${
                              get(item, "remark")
                                ? `(${get(item, "populatedRobotId.remark")})`
                                : ""
                            }`}
                        </div>
                        <div
                          className="flex items-center text-xs"
                          // style={{ fontSize: "0.55rem" }}
                        >
                          <span>
                            {" "}
                            {t("orderNo", sourceKey.highFrequency)} :{" "}
                          </span>
                          <span className="">
                            {/* {get(item, "orderId")?.substring(
                              get(item, "orderId").length - 8
                            )} */}
                            {get(item, "_id")?.substring(
                              get(item, "_id").length - 6
                            ) || "N/A"}
                          </span>
                        </div>
                      </div>
                      {item?.profitType === 1 && (
                        <div className="flex justify-between">
                          <span className="flex items-center  text-xs"></span>
                          <span className={` text-xs`}>
                            {t("unitAmount", sourceKey.highFrequency)} :{" "}
                            {formatDecimalNumber(
                              get(item, "sellInfo.totalCoinEarned"),
                              get(
                                props.product,
                                `coinUnitStructure.${item?.populatedRobotId.symbol}.amount`
                              ) || 2
                            )}
                          </span>{" "}
                        </div>
                      )}
                      <div className="flex justify-between">
                        <div className="flex items-center  text-xs">
                          {/* ITS:{" "}
                          {get(item, "populatedRobotId._id")
                            ? `${get(item, "populatedRobotId._id").substring(
                                get(item, "populatedRobotId._id").length - 4
                              )}`
                            : ""} */}
                          {get(item, "timestamp")
                            ? getFormatedDate(get(item, "timestamp"), true)
                            : ""}
                        </div>

                        <div className="flex justify-end ">
                          {
                            map(profileTransaction, (item, index) => {
                              let trxIn = false;
                              let title2 =
                                convertVsdtTranslation(t, item) || "";
                              if (item?.trxType === "IN") {
                                trxIn = true;
                              }

                              return (
                                <span
                                  className={`${
                                    trxIn === true
                                      ? "text-green-400"
                                      : "text-red-400"
                                  }  pr-1 text-xs flex justify-start`}
                                >
                                  ({title2} )
                                </span>
                              );
                            })
                            // get(item, "profileTransaction")
                            //   ? get(item, "profileTransaction.trxType") ===
                            //     "OUT"
                            //     ? `(${t(
                            //         "deductVSDT",
                            //         sourceKey.highFrequency
                            //       )} ${formatDecimalNumber(
                            //         get(item, "profileTransaction.amount"),
                            //         2
                            //       )} )`
                            //     : `(${t(
                            //         "compesated",
                            //         sourceKey.highFrequency
                            //       )} )`
                            //   : ""
                          }
                          {/* </span>{" "} */}
                          <span>
                            {formatDecimalNumber(
                              get(item, "profit"),
                              numberDecimal
                            )}
                          </span>
                        </div>
                        {/* <div className="flex justify-end ">
                          <span
                            className={`${
                              get(item, "profileTransaction.trxType") === "IN"
                                ? "text-green-400"
                                : "text-red-400"
                            }  pr-1 text-xs`}
                          >
                            {get(item, "profileTransaction")
                              ? `${t(
                                  convertVsdtTranslation(item),
                                  sourceKey.highFrequency
                                )}
                                ${
                                  get(item, "profileTransaction.trxType") ===
                                  "OUT"
                                    ? formatDecimalNumber(
                                        get(item, "profileTransaction.amount"),
                                        2
                                      )
                                    : ""
                                }
                              `
                              : // get(item, "profileTransaction.trxType") ===
                                //   "OUT"
                                //   ? `(${t(
                                //       "deductVSDT",
                                //       sourceKey.highFrequency
                                //     )} ${formatDecimalNumber(
                                //       get(item, "profileTransaction.amount"),
                                //       2
                                //     )} )`
                                //   : `(${t(
                                //       "compesated",
                                //       sourceKey.highFrequency
                                //     )} )`
                                ""}
                          </span>{" "}
                          {formatDecimalNumber(
                            get(item, "profit"),
                            numberDecimal
                          )}
                        </div> */}
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    className=" p-2 mb-2 grid grid-cols-6 items-center border-b-2"
                    style={{
                      cursor: `${
                        productCoinSelected[tab] === 1 ? "pointer" : ""
                      }`,
                    }}
                    key={index}
                    onClick={() => {
                      if (productRecordSelected[tab] === 1) {
                        setDrawerVisible(true);
                        setSelectedData(item);
                      }
                    }}
                  >
                    <span className="inline-block col-span-2 mr-4">
                      {title}
                    </span>
                    <span className="inline-block col-span-2 flex justify-center mr-4">
                      {robotType === robotStrategyDataKey.coin
                        ? t("unrealisedProfit", sourceKey.highFrequency)
                        : t("profit", sourceKey.highFrequency)}
                    </span>
                    <span className="inline-block mr-4 col-span-2 flex justify-end">
                      {formatDecimalNumber(
                        get(item, "totalProfit"),
                        numberDecimal
                      )}
                    </span>
                  </div>
                );
              })}
              <div className="flex justify-end items-center my-5">
                <span className="inline-block ">
                  <Pagination
                    size="small"
                    simple
                    total={get(relatedPagination, `total`, 0)}
                    pageSize={PAGESIZE}
                    current={get(relatedPagination, `page`)}
                    showSizeChanger={false}
                    responsive={true}
                    onChange={(page) => {
                      let newPaginationObj = cloneDeep(productRecordPagination);
                      set(
                        newPaginationObj,
                        `${tab}.${selectedType}.page`,
                        page
                      );
                      setProductRecordPagination(newPaginationObj);
                      getData((page - 1) * PAGESIZE);
                    }}
                  />
                </span>
              </div>
            </React.Fragment>
          ) : (
            <div className="py-9 pb-11">
              <div className="text-center flex justify-center mt-7">
                <img src={not_foundIcon} style={{ height: 70, width: 70 }} />
              </div>
              <div className="text-gray-400 flex justify-center">
                {t("noRecords", sourceKey.highFrequency)}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const _renderCoinList = (tab, selectedType, productCoinPagination) => {
    let isShowDay = selectedType == 0 || selectedType === 4;
    let relatedPagination =
      get(productCoinPagination, `${tab}.${selectedType}`) || {};
    let relatedData = get(relatedPagination, `data`) || [];
    // console.log("coin relatedData", relatedData);
    return (
      <>
        {/* <div className="mx-2 mt-2">{_renderRobotType()}</div> */}
        <div className="  mx-2">
          <div className="flex justify-between mx-1">
            <div>{_renderRecordType()}</div>
            <div
              className="mt-3"
              // style={{
              //   width: "100px",
              // }}
            >
              <Select
                className=""
                value={productCoinSelected[tab]}
                defaultValue={productCoinSelected[tab]}
                onChange={(e) => {
                  setProductCoinSelected({
                    ...productCoinSelected,
                    [tab]: e,
                  });
                }}
              >
                {map(coinProfitType, (item, index) => {
                  return (
                    <Select.Option
                      value={item?.value}
                      style={{
                        width: "100px",
                      }}
                    >
                      {item.title}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>
          {/* <div className="  text-white mb-2" style={{ width: "100%" }}>
            <Input
              onChange={(e) => {
                setSearchSymbol(e.target.value);
              }}
              placeholder={t("search", sourceKey.pairing)}
              style={{
                width: "100%",
                borderRadius: "12px",
                boxShadow: "0px 0px 12px rgba(111, 111, 111, 0.1)",
              }}
              allowClear={true}
              // value={searchValue}
            />
          </div> */}
          {/* <div className=" ">
            <div className="flex flex-wrap justify-around  mt-2 mb-4">
              <span className="inline-block mr-2 mt-2">
                <Button
                  shape="round"
                  className={`p-2 ${
                    isShowDay && productCoinSelected[tab] === 0
                      ? "main-button-color"
                      : "second-button-color"
                  }`}
                  onClick={(e) => {
                    setProductCoinSelected({
                      ...productCoinSelected,
                      [tab]: 0,
                    });
                  }}
                >
                  {t("today", sourceKey.pairing)}
                </Button>
              </span>
              <span className="inline-block mr-2 mt-2 ">
                <Button
                  shape="round"
                  className={`p-2 ${
                    !isShowDay && productCoinSelected[tab] === 1
                      ? "main-button-color"
                      : "second-button-color"
                  }`}
                  onClick={(e) => {
                    setProductCoinSelected({
                      ...productCoinSelected,
                      [tab]: 1,
                    });
                  }}
                >
                  {t("daily", sourceKey.pairing)}
                </Button>
              </span>
              <span className="inline-block mr-2 mt-2">
                <Button
                  shape="round"
                  className={`p-2 ${
                    !isShowDay && productCoinSelected[tab] === 3
                      ? "main-button-color"
                      : "second-button-color"
                  }`}
                  onClick={(e) => {
                    setProductCoinSelected({
                      ...productCoinSelected,
                      [tab]: 3,
                    });
                  }}
                >
                  {t("weekly", sourceKey.pairing)}
                </Button>
              </span>
              <span className="inline-block mr-2 mt-2 ">
                <Button
                  shape="round"
                  className={`p-2 ${
                    !isShowDay && productCoinSelected[tab] === 2
                      ? "main-button-color"
                      : "second-button-color"
                  }`}
                  onClick={(e) => {
                    setProductCoinSelected({
                      ...productCoinSelected,
                      [tab]: 2,
                    });
                  }}
                >
                  {t("monthly", sourceKey.pairing)}
                </Button>
              </span>
              <span className="inline-block mt-2 ">
                <Button
                  shape="round"
                  className={`p-2 ${
                    isShowDay && productCoinSelected[tab] === 4
                      ? "main-button-color"
                      : "second-button-color"
                  }`}
                  onClick={(e) => {
                    setProductCoinSelected({
                      ...productCoinSelected,
                      [tab]: 4,
                    });
                  }}
                >
                  {t("total", sourceKey.pairing)}
                </Button>
              </span>
            </div>
          </div> */}
        </div>
        <div className="rounded-xl lightgreybox-bg-color  mx-1 py-1 px-2 mb-5 ">
          {/* <span className="inline-block text-sm font-semibold">
            {isShowDay
              ? t("profitToday", sourceKey.pairing)
              : t("totalProfit", sourceKey.pairing)}
          </span> */}
          {arrayLoading ? (
            <>
              <Skeleton active rows={5}></Skeleton>
              <Skeleton active rows={5}></Skeleton>
              <Skeleton active rows={5}></Skeleton>
              <Skeleton active rows={5}></Skeleton>
              <Skeleton active rows={5}></Skeleton>
              <Skeleton active rows={5}></Skeleton>
            </>
          ) : isArray(relatedData) && !isEmpty(relatedData) ? (
            <React.Fragment>
              {map(relatedData, (item, index) => {
                return (
                  <div
                    className=" p-2 mb-3 flex items-center border-b-2"
                    // style={{ cursor: "pointer" }}
                    key={index}
                    // onClick={() => {
                    //   setDrawerVisible(true);
                    //   setSelectedData(item);
                    // }}
                  >
                    {isShowDay ? (
                      <>
                        <span className="inline-block mr-4">{index + 1}</span>
                        <span className="flex justify-between items-center w-full">
                          <span className="inline-block mr-2">
                            {get(item, "symbol")}
                          </span>
                          <span className="inline-block flex justify-start">
                            <span>
                              {robotType === robotStrategyDataKey.usdt &&
                              item?.totalExcludeProfit > 0 ? (
                                <span className=" mr-1 text-xs text-red-400">
                                  ({"-ITSV "}
                                  {formatDecimalNumber(
                                    get(item, "totalExcludeProfit"),
                                    numberDecimal
                                  )}{" "}
                                  )
                                </span>
                              ) : (
                                ""
                              )}

                              {robotType === robotStrategyDataKey.coin
                                ? formatDecimalNumber(
                                    get(item, "totalProfit"),
                                    get(
                                      props.product,
                                      `coinUnitStructure.${item?.symbol}.amount`
                                    ) || 2
                                  )
                                : formatDecimalNumber(
                                    get(item, "totalOriginProfit"),
                                    numberDecimal
                                  )}
                            </span>
                          </span>
                        </span>
                      </>
                    ) : (
                      <>
                        {/* <div className="flex justify-between items-center w-full"> */}
                        <div
                          className="grid grid-cols-6 w-full"
                          onClick={() => {
                            setCoinDrawerVisible(true);
                            setSelectedData(item);
                          }}
                        >
                          <div className="inline-block col-span-2 mr-2">
                            <div className="text-sm">{get(item, "day")}</div>
                          </div>
                          <div className="inline-block mr-2 col-span-2 flex justify-center">
                            {/* {get(item, "symbol")} */}
                            {robotType === robotStrategyDataKey.coin
                              ? t("totalCoinEarned", sourceKey.highFrequency)
                              : t("profit", sourceKey.highFrequency)}
                          </div>
                          <span className="inline-block col-span-2 flex justify-end">
                            {formatDecimalNumber(
                              get(
                                item,
                                robotType === robotStrategyDataKey.coin
                                  ? "totalCoinEarned"
                                  : "totalProfit"
                              ),
                              robotType === robotStrategyDataKey.coin
                                ? get(
                                    props.product,
                                    `coinUnitStructure.${item?.symbol}.amount`
                                  ) || 2
                                : numberDecimal
                            )}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}

              <div className="flex justify-end items-center my-5">
                <span className="inline-block ">
                  <Pagination
                    simple
                    size="small"
                    total={get(relatedPagination, `total`, 0)}
                    pageSize={PAGESIZE}
                    current={get(relatedPagination, `page`)}
                    showSizeChanger={false}
                    responsive={true}
                    onChange={(page) => {
                      let newPaginationObj = cloneDeep(productCoinPagination);
                      set(
                        newPaginationObj,
                        `${tab}.${selectedType}.page`,
                        page
                      );
                      setProductCoinPagination(newPaginationObj);
                      getData((page - 1) * PAGESIZE);
                    }}
                  />
                </span>
              </div>
            </React.Fragment>
          ) : (
            <div className="py-9 pb-11">
              <div className="text-center flex justify-center mt-7">
                <img src={not_foundIcon} style={{ height: 70, width: 70 }} />
              </div>
              <div className="text-gray-400 flex justify-center">
                {t("noRecords", sourceKey.highFrequency)}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const _renderRobotType = () => {
    return (
      <div className="flex justify-start items-center mt-4">
        {map(robotStrategy, (item, index) => {
          return (
            <span
              className={`inline-block mr-2   flex justify-start rounded-xl py-2 pl-2 pr-3 cursor-pointer`}
              style={{
                height: "60px",
                background: `${
                  robotType === item.key ? "#e6ab27 " : "#545454"
                }`,
              }}
              onClick={() => {
                setRobotType(item.key);
              }}
            >
              <img
                src={get(item, "img")}
                style={{
                  width: item.iconSizeWidth,
                  height: item.iconSizeHeight,
                }}
              />
              <span className="ml-2 text-lg font-bold flex justify-center items-center">
                {t(item.title, sourceKey.highFrequency)}
              </span>
              {/* <Button
                shape="round"
                className={`main-button-padding ${
                  robotType === item?.key
                    ? "main-button-color"
                    : "second-button-color"
                }`}
                onClick={(e) => {
                  setRobotType(item?.key);
                }}
              >
                {t(item?.title, sourceKey.highFrequency)}
              </Button> */}
            </span>
          );
        })}

        {/* <span className="inline-block ">
          <Button
            shape="round"
            className={`main-button-padding ${
              robotType === robotStrategyDataKey.coin
                ? "main-button-color"
                : "second-button-color"
            }`}
            onClick={(e) => {
              setRobotType(robotStrategyDataKey.coin);
            }}
          >
            {t("coinITS", sourceKey.highFrequency)}
          </Button>
        </span> */}
      </div>
    );
  };
  const _renderRecordType = () => {
    return (
      <div className="flex justify-center items-center my-4">
        <span className="inline-block mr-2">
          <Button
            shape="round"
            className={`main-button-padding ${
              showRecord ? "main-button-color" : "second-button-color"
            }`}
            onClick={(e) => {
              setShowRecord(true);
            }}
          >
            {t("record", sourceKey.highFrequency)}
          </Button>
        </span>
        <span className="inline-block ">
          <Button
            shape="round"
            className={`main-button-padding ${
              !showRecord ? "main-button-color" : "second-button-color"
            }`}
            onClick={(e) => {
              setShowRecord(false);
            }}
          >
            {t("coin", sourceKey.highFrequency)}
          </Button>
        </span>
      </div>
    );
  };

  const _renderCodeType = () => {
    const codeTypeList = [
      {
        text: "All",
        value: "all",
      },
      {
        text: "HF1",
        value: "HF1",
      },
      {
        text: "HF3",
        value: "HF3",
      },
    ];
    return (
      <div className="flex justify-center items-center my-4">
        {map(codeTypeList, (item, index) => {
          return (
            <span className="mr-2">
              <Button
                style={{
                  borderColor: `${
                    get(item, "value") === codeType ? "#FCD535" : ""
                  }`,
                  color: `${get(item, "value") === codeType ? "#FCD535" : ""}`,
                }}
                onClick={() => {
                  setCodeType(get(item, "value"));
                }}
              >
                {get(item, "text")}
              </Button>
            </span>
          );
        })}
      </div>
    );
  };
  // console.log("outside", totalProfit);

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            suffix={
              <div className="justify-end flex items-center">
                {/* 历史成交{" "}
                <MediaQuery maxWidth={440}>
                  <br />
                </MediaQuery> */}
                {t("transaction", sourceKey.highFrequency)}
              </div>
            }
            showBack={true}
          >
            <div className="mx-4">
              {/* <div className="my-4 mx-2">{_renderRobotType()}</div> */}

              <div
                className={`grid grid-cols-${
                  robotType === robotStrategyDataKey.usdt ? "2" : "2"
                } gap-2 rounded-3xl mx-2 pt-4 pb-2`}
              >
                <div class="  col-span-2 flex rounded-2xl justify-between  bg-main-color-gradient items-center p-3 shadow-bg  mb-1 ">
                  <div class=" font-light font-semibold  text-center">
                    {t("profitToday", sourceKey.pairing)}
                  </div>

                  <div className="text-base font-semibold text-black text-center">
                    {formatDecimalNumber(todayProfit || 0, numberDecimal)}
                  </div>
                </div>

                <div class="  col-span-2 flex rounded-2xl justify-between  bg-main-color-gradient items-center shadow-bg p-3 mb-1 ">
                  <div class="flex font-light font-semibold text-center">
                    <span>
                      {robotType === robotStrategyDataKey.coin
                        ? t("unrealisedProfit", sourceKey.highFrequency)
                        : t("accumulated", sourceKey.highFrequency)}
                    </span>
                    <div className="text-xs font-light  text-center flex justify-center">
                      <Tooltip
                        className="text-s pl-1 pt-1"
                        overlayStyle={{ whiteSpace: "pre-line" }}
                        trigger="click"
                        placement="top"
                        title={
                          <div
                            style={{
                              color: tooltipTextColor,
                            }}
                          >
                            <div>
                              {t("accumulateDesc1", sourceKey.highFrequency)}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className="" />
                      </Tooltip>
                    </div>
                  </div>

                  <div className="text-base font-semibold text-black text-center">
                    {formatDecimalNumber(totalProfit || 0, numberDecimal)}
                  </div>
                </div>
                <div class="  col-span-2 flex rounded-2xl justify-between  bg-main-color-gradient items-center shadow-bg p-3 mb-1 ">
                  <div class="flex font-light font-semibold text-center">
                    <span>{t("netProfit", sourceKey.highFrequency)}</span>

                    <div className="text-xs font-light  text-center flex justify-center">
                      <Tooltip
                        className="text-s pl-1 pt-1"
                        overlayStyle={{ whiteSpace: "pre-line" }}
                        trigger="click"
                        placement="top"
                        title={
                          <div
                            style={{
                              color: tooltipTextColor,
                            }}
                          >
                            <div>{t("netDesc", sourceKey.highFrequency)}</div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className="" />
                      </Tooltip>
                    </div>
                  </div>

                  <div className="text-base font-semibold text-black text-center">
                    {formatDecimalNumber(netProfit || 0, numberDecimal)}
                  </div>
                </div>

                {/* <div className=" col-span-2 flex rounded-2xl   bg-main-color-gradient items-center shadow-bg px-3 mb-1">
                  <div
                    className="grid grid-cols-2 "
                    style={{
                      width: "100%",
                    }}
                  >
                    <div className="col-span-1 flex justify-between py-1 my-2">
                      <span>{t("ITSV", sourceKey.profile)}</span>

                      <span className="text-base font-semibold text-black text-center">
                        {formatDecimalNumber(
                          get(profile, "balanceITSV") || 0,
                          numberDecimal
                        )}
                      </span>
                    </div>
                    <div className="col-span-1 border-l-2 pl-2 ml-2 flex justify-between py-1 my-2" style={{
                      borderLeftColor: "white"
                    }}>
                      <span>{t("VSDT", sourceKey.profile)}</span>

                      <span className="text-base font-semibold text-black text-center">
                        {formatDecimalNumber(
                          get(profile, "balanceVsdt") || 0,
                          numberDecimal
                        )}
                      </span>
                    </div>
                  </div>
                </div> */}

                <div class="  col-span-1 flex rounded-2xl justify-between  bg-main-color-gradient items-center shadow-bg p-3 mb-1 ">
                  <div class="flex font-light font-semibold text-center">
                    <span> {t("ITSV", sourceKey.profile)}</span>

                    <div className="text-xs font-light  text-center flex justify-center">
                      <Tooltip
                        className="text-s pl-1 pt-1"
                        overlayStyle={{ whiteSpace: "pre-line" }}
                        trigger="click"
                        placement="top"
                        title={
                          <div
                            style={{
                              color: tooltipTextColor,
                            }}
                          >
                            <div>{t("itsvDesc", sourceKey.highFrequency)}</div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className="" />
                      </Tooltip>
                    </div>
                  </div>

                  <div className="text-base font-semibold text-black text-center">
                    {formatDecimalNumber(
                      get(profile, "balanceITSV") || 0,
                      numberDecimal
                    )}
                  </div>
                </div>
                {robotType === robotStrategyDataKey.usdt ? (
                  <div class="  col-span-1 flex rounded-2xl justify-between   bg-main-color-gradient items-center p-3 shadow-bg mb-1 ">
                    <div class=" font-light font-semibold text-center">
                      VSDT
                    </div>

                    <div className="text-base font-semibold text-black text-center">
                      {formatDecimalNumber(
                        get(profile, "balanceVsdt") || 0,
                        numberDecimal
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* <div className="col-span-2 ">
                  <div className="mb-2  text-black">
                    <div className="font-semibold text-center text-2xl pt-4">
                      {formatDecimalNumber(todayProfit || 0, numberDecimal)}
                    </div>
                    <div className="pb-1.5 pt-1  rounded-md p-2 sm:px-10 mr-2 text-center">
                      {robotType === robotStrategyDataKey.coin
                        ? t("unrealisedProfitToday", sourceKey.highFrequency)
                        : t("profitToday", sourceKey.pairing)}
                    </div>
                  </div>
                </div>

                <div className="col-span-2">
                  <div className="mb-2  text-black">
                    <div className="font-semibold text-center text-2xl pt-4">
                      {formatDecimalNumber(totalProfit || 0, numberDecimal)}
                    </div>
                    <div className="pb-1.5 pt-1  rounded-md p-2 sm:px-10 mr-2 text-center">
                      {robotType === robotStrategyDataKey.coin
                        ? t("unrealisedProfit", sourceKey.highFrequency)
                        : t("accumulated", sourceKey.highFrequency)}
                    </div>
                  </div>
                </div>
                {robotType === robotStrategyDataKey.usdt ? (
                  <div className="col-span-2">
                    <div className="mb-2  text-black">
                      <div className="font-semibold text-center text-2xl pt-4">
                        {formatDecimalNumber(
                          get(profile, "balanceVsdt") || 0,
                          numberDecimal
                        )}
                      </div>
                      <div className="pb-1.5 pt-1  rounded-md p-2 sm:px-10 mr-2 text-center">
                        (VSDT)
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
              </div>

              <div className="">
                {/* <div className="text-center text-xs text-yellow-300 border-b-2 mt-5">
                 
                  {t("highFrequency", sourceKey.highFrequency)}
                </div> */}

                {/* {_renderCodeType()} */}
                {/* {_renderRecordType()} */}

                {showRecord
                  ? _renderRecordList(
                      "2",
                      get(productRecordSelected, "2"),
                      productRecordPagination
                    )
                  : _renderCoinList(
                      "2",
                      get(productCoinSelected, "2"),
                      productCoinPagination
                    )}
              </div>
            </div>
            <DailyProfitReportDrawer
              visible={drawerVisible}
              data={selectedData}
              robotType={robotType}
              robotProfiles={robotProfiles}
              profile={profile}
              filterGroup={filterList}
              onClose={() => {
                setDrawerVisible(false);
                setSelectedData({});
              }}
            />
            <CoinProfitDetailsDrawer
              visible={coinDrawerVisible}
              data={selectedData}
              robotType={robotType}
              robotProfiles={robotProfiles}
              productCoinSelected={convertNumberToDateCharacter(
                get(productCoinSelected, "2")
              )}
              profile={profile}
              filterGroup={filterList}
              onClose={() => {
                setCoinDrawerVisible(false);
                setSelectedData({});
              }}
            />
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  product: state.product,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(mapStateToProps, mapDispatchToProps)(RobotPage);
