import { Drawer, Pagination, Spin } from "antd";
import { get, isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getCoinProfitData from "../../../newApi/robots/getCoinProfitData";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { formatDecimalNumber } from "../../../utilities/common-function";
import { robotStrategyDataKey } from "../../../utilities/data";
import { numberDecimal } from "../../../utilities/startUp";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

// markup
const PAGESIZE = 10;

const CoinProfitDetailsDrawer = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  let data = get(props, "data");
  let profitType = props?.productCoinSelected;
  let robotType = props?.robotType;
  let robotProfiles = get(props, "robotProfiles");
  //   let filterGroup_tra = get(props, "filterGroup");

  //   if (get(filterGroup_tra, "type") === "day") {
  //     //let temp_filter = cloneDeep(filterGroup);moment2().tz("Asia/Kuala_Lumpur").startOf("D").format()
  //     // let startDate = moment(get(data, "date")).format("YYYY-MM-DD");
  //     let startDate = moment2(get(data, "date"))
  //       .tz(timeZone)
  //       .startOf("D")
  //       .format();
  //     // let endDate = moment(get(data, "date")).format("YYYY-MM-DD");
  //     let endDate = moment2(get(data, "date")).tz(timeZone).endOf("D").format();
  //     // let startDateTime = moment2(startDate + " " + "00:00").tz("Asia/Kuala_Lumpur");
  //     // let endDateTime = moment2(endDate + " " + "23:59").tz("Asia/Kuala_Lumpur");
  //     set(filterGroup_tra, "timestampMin", startDate);
  //     set(filterGroup_tra, "timestampMax", endDate);
  //     // console.log(filterGroup_tra)
  //   }

  useEffect(() => {
    setVisible(props.visible === true);
    // setFilterGroup(filterGroup_tra);
    if (props.visible === true) {
      getDayData((page - 1) * PAGESIZE);
    }
  }, [props.visible]);

  useEffect(() => {
    getDayData((page - 1) * PAGESIZE);
  }, [page]);

  function close() {
    setDataSource([]);
    setTotal(0);
    setPage(1);
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
  }

  function getDayData(skip) {
    setLoading(true);
    if (!isEmpty(robotProfiles)) {
      //   console.log("data", data);
      getCoinProfitData(PAGESIZE, skip, {
        profileIdIn: robotProfiles,
        robotType: robotType,
        type: profitType,
        dateStr: data?.day,
        liquidation: 0,
        ignoreProfitNe: 1,
        sort: {
          //   profitDate: -1,
          totalOriginProfit: -1,
          totalProfit: -1,
          "sellInfo.totalProfit": -1,
        },
      })
        .then((res) => {
          // console.log("res", res);
          let data = get(res, "data");
          if (!isEmpty(data)) {
            setDataSource(data);
            setTotal(get(res, "total"));
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
      //   getRobotTransactions(PAGESIZE, skip, {
      //     profileIdIn: robotProfiles,
      //     symbol: get(data, "_id.symbol"),
      //     ...filterGroup_tra,
      //     showRecord: true,
      //     robotType: props.robotType,
      //     liquidation: 0,
      //     type: "",
      //     sort: {
      //       timestamp: -1,
      //       "sellInfo.totalProfit": 1,
      //     },
      //   })
      //     .then((res) => {
      //       // console.log("res", res);
      //       let data = get(res, "data");
      //       if (!isEmpty(data)) {
      //         setDataSource(data);
      //         setTotal(get(res, "total"));
      //       }
      //       setLoading(false);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       message.error(err?.message);
      //       setLoading(false);
      //     });
    }
  }

  return (
    <React.Fragment>
      <Drawer
        width="100%"
        visible={visible}
        className="body-no-padding"
        closable={false}
        onClose={() => close()}
      >
        <Layout>
          <Spin spinning={loading}>
            <NavHeader
              suffix={
                <div className="justify-end flex items-center">
                  {t("coinDetails", sourceKey.highFrequency)}
                </div>
              }
              showBack={true}
              onBack={() => {
                close();
              }}
            >
              <div className="rounded-xl lightgreybox-bg-color box-shadow-center mx-2 py-3 px-2 my-5 ">
                <div className="flex justify-between">
                  <span className="inline-block font-semibold">
                    {get(data, "day")}
                  </span>
                  <span className="inline-block flex font-semibold">
                    {t("total", sourceKey.pairing)} :{" "}
                    {formatDecimalNumber(
                      get(
                        data,
                        robotType === robotStrategyDataKey.coin
                          ? "totalCoinEarned"
                          : "totalProfit"
                      ),
                      numberDecimal
                    )}
                  </span>
                </div>

                <div>
                  {map(dataSource, (item, index) => {
                    return (
                      <div
                        className=" p-2 mb-3 flex items-center border-b-2"
                        key={index}
                      >
                        <span className="inline-block mr-4">{index + 1}</span>
                        <span className="flex justify-between items-center w-full">
                          <span className="inline-block mr-2">
                            {get(item, "symbol")}
                          </span>
                          <span className="inline-block flex justify-start">
                            {robotType === robotStrategyDataKey.usdt &&
                            item?.totalExcludeProfit !== 0 ? (
                              <span className=" mr-1 text-xs text-red-400">
                                ({"-ITSV "}
                                {formatDecimalNumber(
                                  get(item, "totalExcludeProfit"),
                                  numberDecimal
                                )}{" "}
                                )
                              </span>
                            ) : (
                              ""
                            )}

                            {robotType === robotStrategyDataKey.coin
                              ? formatDecimalNumber(
                                  get(item, "totalCoinEarned"),
                                  get(
                                    props.product,
                                    `coinUnitStructure.${item?.symbol}.amount`
                                  ) || 2
                                )
                              : formatDecimalNumber(
                                  get(item, "totalOriginProfit") === 0
                                    ? get(item, "totalProfit")
                                    : get(item, "totalOriginProfit"),
                                  numberDecimal
                                )}
                            {/* {formatDecimalNumber(
                              get(
                                item,
                                robotType === robotStrategyDataKey.coin
                                  ? "totalCoinEarned"
                                  : "totalProfit"
                              ),
                              robotType === robotStrategyDataKey.coin
                                ? get(
                                    props.product,
                                    `coinUnitStructure.${item?.symbol}.amount`
                                  ) || 2
                                : numberDecimal
                            )} */}
                          </span>
                        </span>
                      </div>
                    );
                  })}
                </div>

                <div className="flex justify-end items-center my-5">
                  <span className="inline-block ">
                    <Pagination
                      simple
                      size="small"
                      total={total}
                      pageSize={PAGESIZE}
                      current={page}
                      showSizeChanger={false}
                      onChange={(page) => {
                        setPage(page);
                      }}
                    />
                  </span>
                </div>
              </div>
            </NavHeader>
          </Spin>
        </Layout>
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  product: state.product,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoinProfitDetailsDrawer);
