import { robotPrefix } from ".";
import { apiUrl, _axios_base_get_list } from "..";

const PAGESIZE = 30;

export default function getCoinProfitData(
    limit = PAGESIZE,
    skip = 0,
    query = {}
) {
    return _axios_base_get_list(
        `${apiUrl}/${robotPrefix}/getCoinProfitData`,
        limit,
        skip,
        query
    );
}
