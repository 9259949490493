import { set } from "@antv/util";
import { Button, Drawer, message, Pagination, Spin } from "antd";
import { cloneDeep, get, isEmpty, map } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getRobotTransactions from "../../../newApi/robots/getRobotTransactions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import {
  ExportToExcel,
  formatDecimalNumber,
} from "../../../utilities/common-function";
import { numberDecimal, timeZone } from "../../../utilities/startUp";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import moment2 from "moment-timezone";
import { defaultPopulatedFieldName } from "../../../newApi";
import { getFormatedDate } from "../config";
import getRobotTransactions2 from "../../../newApi/robots/getRobotTransactions2";
import { convertVsdtTranslation } from "../../../utilities/profile";

// markup
const PAGESIZE = 10;

const DailyProfitReportDrawer = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [filterGroup, setFilterGroup] = useState({});
  const [detailData, setDetailData] = useState([]);
  const [detailsPage, setDetailsPage] = useState(1);
  const [detailsTotal, setDetailsTotal] = useState(0);
  const [profitTotal, setProfitTotal] = useState(0);
  let data = get(props, "data");
  let robotProfiles = get(props, "robotProfiles");
  let filterGroup_tra = get(props, "filterGroup");

  if (get(filterGroup_tra, "type") === "day") {
    //let temp_filter = cloneDeep(filterGroup);moment2().tz("Asia/Kuala_Lumpur").startOf("D").format()
    // let startDate = moment(get(data, "date")).format("YYYY-MM-DD");
    let startDate = moment2(get(data, "date"))
      .tz(timeZone)
      .startOf("D")
      .format();
    // let endDate = moment(get(data, "date")).format("YYYY-MM-DD");
    let endDate = moment2(get(data, "date")).tz(timeZone).endOf("D").format();
    // let startDateTime = moment2(startDate + " " + "00:00").tz("Asia/Kuala_Lumpur");
    // let endDateTime = moment2(endDate + " " + "23:59").tz("Asia/Kuala_Lumpur");
    set(filterGroup_tra, "timestampMin", startDate);
    set(filterGroup_tra, "timestampMax", endDate);
    // console.log(filterGroup_tra)
  }

  useEffect(() => {
    let visible_parent = cloneDeep(get(props, "visible"));

    setVisible(props.visible === true);
    setFilterGroup(filterGroup_tra);
    if (visible_parent === true) {
      getDayData((page - 1) * PAGESIZE);
    }
  }, [props.visible]);

  useEffect(() => {
    getDetailsData((detailsPage - 1) * PAGESIZE);
  }, [detailsVisible]);

  useEffect(() => {
    getDayData((page - 1) * PAGESIZE);
  }, [page]);

  useEffect(() => {
    getDetailsData((detailsPage - 1) * PAGESIZE);
  }, [detailsPage]);

  function close() {
    setDataSource([]);
    setTotal(0);
    setPage(1);
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
  }

  function getDayData(skip) {
    setLoading(true);
    if (!isEmpty(robotProfiles)) {
      getRobotTransactions2(PAGESIZE, skip, {
        profileIdIn: robotProfiles,
        symbol: get(data, "_id.symbol"),
        ...filterGroup_tra,
        showRecord: true,
        robotType: props.robotType,
        liquidation: 0,
        type: "",
        sort: {
          timestamp: -1,
          "sellInfo.totalProfit": 1,
        },
      })
        .then((res) => {
          // console.log("res", res);
          let data = get(res, "data");
          if (!isEmpty(data)) {
            setDataSource(data);
            setTotal(get(res, "total"));
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          message.error(err?.message);
          setLoading(false);
        });
    }

    // getProfileRobotProfit(PAGESIZE, skip, {
    //   //type: "coin",
    //   profileIdIn: robotProfiles,
    //   symbol: get(data, "_id.symbol"),
    //   ...filterGroup_tra,
    // })
    //   .then((res) => {
    //     // console.log("res", res);
    //     let data = get(res, "data");
    //     let orderId = map(data, (item, index) => {
    //       return get(item, "_id");
    //     });
    //     // console.log(orderId);

    //     getProfileTransactions("all", 0, {
    //       orderIdIn: orderId,
    //     })
    //       .then((t_rs) => {
    //         // console.log("profilet", t_rs);
    //         let profileTransaction = get(t_rs, "data");
    //         data = map(data, (item, index) => {
    //           let vsdtRecord = filter(profileTransaction, (i, index) => {
    //             return get(i, "orderId") === get(item, "_id");
    //           });
    //           vsdtRecord = vsdtRecord[0];
    //           if (!isEmpty(vsdtRecord)) {
    //             item.profileTransaction = vsdtRecord;
    //           }

    //           return item;
    //         });
    //         // console.log("profilet data", data);
    //         if (!isEmpty(data)) {
    //           setDataSource(data);
    //           setTotal(get(res, "total"));
    //         }
    //         setLoading(false);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         setLoading(false);
    //         message.error(err?.message);
    //       });

    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     //console.log(err);
    //     setLoading(false);
    //   });
  }

  function getDetailsData(skip) {
    //setLoading(true);
    // getDailyProfitReport(PAGESIZE, skip, {
    //   profileIdIn: robotProfiles,
    //   symbol: get(data, "_id.symbol"),
    //   ...filterGroup,
    //   type: "coindate",
    // })
    //   .then((res) => {
    //     //console.log("res", res);
    //     let temp_data = get(res, "data");
    //     if (!isEmpty(temp_data)) {
    //       setDetailData(temp_data);
    //       setDetailsTotal(get(res, "total"));
    //     }
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     //console.log(err);
    //     setLoading(false);
    //   });
  }

  return (
    <React.Fragment>
      <Drawer
        width="100%"
        visible={visible}
        className="body-no-padding"
        closable={false}
        onClose={() => close()}
      >
        <Layout>
          <Spin spinning={loading}>
            <NavHeader
              suffix={
                <div className="justify-end flex items-center">
                  {get(filterGroup_tra, "type") === "coin" ? (
                    <>
                      {/* 币种每日盈利{" "}
                      <MediaQuery maxWidth={440}>
                        <br />
                      </MediaQuery> */}
                      {t("coinDaily", sourceKey.highFrequency)}
                    </>
                  ) : (
                    <>
                      {/* 单日盈利详情{" "}
                      <MediaQuery maxWidth={440}>
                        <br />
                      </MediaQuery> */}

                      {t("oneDay", sourceKey.highFrequency)}
                    </>
                  )}
                </div>
              }
              showBack={true}
              onBack={() => {
                close();
              }}
            >
              <div className="rounded-xl lightgreybox-bg-color box-shadow-center mx-2 py-3 px-2 my-5 ">
                {/* <Button
                  onClick={() => {
                    getRobotTransactions("all", 0, {
                      profileIdIn: robotProfiles,
                      symbol: get(data, "_id.symbol"),
                      ...filterGroup_tra,
                      showRecord: true,
                      robotType: props.robotType,
                      liquidation: 0,
                      type: "",
                      sort: {
                        timestamp: -1,
                      },
                    })
                      .then((res) => {
                        console.log("res", res);
                        let data = get(res, "data");
                        let columns = [
                          {
                            title: "Symbol",
                            render: (value, record) =>
                              get(
                                record,
                                `${defaultPopulatedFieldName("robotId")}.symbol`
                              ),
                          },
                          {
                            title: "profit",
                            render: (value, record) => get(record, "profit"),
                          },
                        ];
                        // if (!isEmpty(data)) {
                        //   setDataSource(data);
                        //   setTotal(get(res, "total"));
                        // }
                        ExportToExcel(data, columns, "name");
                        setLoading(false);
                      })
                      .catch((err) => {
                        console.log(err);
                        message.error(err?.message);
                        setLoading(false);
                      });
                  }}
                >
                  Export
                </Button> */}
                <div className="flex justify-between">
                  <span className="inline-block font-semibold">
                    {get(filterGroup_tra, "type") === "coin"
                      ? get(data, "_id.symbol")
                      : get(data, "date")
                        ? getFormatedDate(get(data, "date"), false)
                        : ""}
                  </span>
                  <span className="inline-block flex font-semibold">
                    {t("total", sourceKey.pairing)} :{" "}
                    {formatDecimalNumber(
                      get(data, "totalProfit"),
                      numberDecimal
                    )}
                  </span>
                </div>

                {isEmpty(dataSource) ? (
                  ""
                ) : get(filterGroup_tra, "type") === "coin" ? (
                  <>
                    {map(dataSource, (item, index) => {
                      return (
                        <div
                          className="  p-2 mb-3 flex justify-around items-center border-b-2"
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            let temp_filter = cloneDeep(filterGroup);
                            let startDate = moment(
                              get(item, "binanceStock.transactionTime")
                            ).format("YYYY-MM-DD");
                            let endDate = moment(
                              get(item, "binanceStock.transactionTime")
                            ).format("YYYY-MM-DD");
                            let startDateTime = moment(
                              startDate + " " + "00:00"
                            );
                            let endDateTime = moment(endDate + " " + "23:59");
                            set(
                              temp_filter,
                              "transactionTimeMin",
                              startDateTime.toDate()
                            );
                            set(
                              temp_filter,
                              "transactionTimeMax",
                              endDateTime.toDate()
                            );
                            setProfitTotal(get(item, "totalProfit"));
                            setFilterGroup(temp_filter);
                            setDetailsVisible(true);
                          }}
                        >
                          <span className="inline-block mr-4">
                            {/* {get(item, "_id.date")
                                                            ? get(item, "_id.date")
                                                            : ""} */}
                            {get(item, "binanceStock.transactionTime")
                              ? moment(
                                get(item, "binanceStock.transactionTime")
                              ).format("DD/MM/YYYY")
                              : ""}
                          </span>
                          <span className="inline-block mr-4">
                            {formatDecimalNumber(
                              get(item, "totalProfit"),
                              numberDecimal
                            )}
                          </span>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {map(dataSource, (item, index) => {
                      let profileTransaction = item?.profileTransaction || [];
                      let freeTransaction = false;
                      if (profileTransaction.length > 0) {
                        freeTransaction = true;
                      }
                      return (
                        <div
                          className=" p-2  border-dotted border-b-2  "
                          key={index}
                          style={{ backgroundColor: "#20262F" }}
                        >
                          <div className="flex justify-between">
                            <div className="flex items-center">
                              {get(item, "populatedRobotId.symbol") +
                                "-" +
                                `${get(item, "populatedRobotId._id")
                                  ? `${get(
                                    item,
                                    "populatedRobotId._id"
                                  ).substring(
                                    get(item, "populatedRobotId._id")
                                      .length - 4
                                  )}`
                                  : ""
                                } ${get(item, "remark")
                                  ? `(${get(
                                    item,
                                    "populatedRobotId.remark"
                                  )})`
                                  : ""
                                }`}
                            </div>
                            <div
                              className="flex items-center text-xs"
                            // style={{ fontSize: "0.55rem" }}
                            >
                              <span>
                                {" "}
                                {t("orderNo", sourceKey.highFrequency)} :{" "}
                              </span>
                              <span className="">
                                {get(item, "_id")?.substring(
                                  get(item, "_id").length - 6
                                )}
                              </span>
                            </div>
                          </div>
                          {item?.profitType === 1 && (
                            <div className="flex justify-between">
                              <span className="flex items-center  text-xs"></span>
                              <span className={` text-xs`}>
                                {t("unit", sourceKey.highFrequency)} :{" "}
                                {formatDecimalNumber(
                                  get(item, "sellInfo.totalCoinEarned"),
                                  get(
                                    props.product,
                                    `coinUnitStructure.${item?.populatedRobotId.symbol}.amount`
                                  ) || 2
                                )}
                              </span>{" "}
                            </div>
                          )}
                          <div className="flex justify-between">
                            <div className="flex items-center  text-xs">
                              {/* ITS:{" "}
                          {get(item, "populatedRobotId._id")
                            ? `${get(item, "populatedRobotId._id").substring(
                                get(item, "populatedRobotId._id").length - 4
                              )}`
                            : ""} */}
                              {get(item, "timestamp")
                                ? getFormatedDate(get(item, "timestamp"), true)
                                : ""}
                            </div>

                            <div className="flex justify-end ">
                              {
                                map(profileTransaction, (item, index) => {
                                  let trxIn = false;
                                  let title =
                                    convertVsdtTranslation(t, item) || "";
                                  if (item?.trxType === "IN") {
                                    trxIn = true;
                                  }

                                  return (
                                    <span
                                      className={`${trxIn === true
                                        ? "text-green-400"
                                        : "text-red-400"
                                        }  pr-1 text-xs flex justify-start`}
                                    >
                                      ({title} )
                                    </span>
                                  );
                                })}
                              {/* </span>{" "} */}
                              <span>
                                {formatDecimalNumber(
                                  get(item, "profit"),
                                  numberDecimal
                                )}
                              </span>
                            </div>
                          </div>
                          {/* <div className="flex justify-between">
                        <span className="flex items-center  text-xs">
                          {get(item, "timestamp")
                            ? moment(get(item, "timestamp")).format(
                                "DD/MM/YYYY hh:mm:ss A"
                              )
                            : ""}
                        </span>
                        <span
                          className={`${
                            get(item, "profileTransaction.trxType") === "IN"
                              ? "text-green-400"
                              : "text-red-400"
                          } text-xs`}
                        >
                          {get(item, "profileTransaction")
                            ? get(item, "profileTransaction.trxType") === "OUT"
                              ? `(${t(
                                  "deductVSDT",
                                  sourceKey.highFrequency
                                )} ${formatDecimalNumber(
                                  get(item, "profileTransaction.amount"),
                                  2
                                )} )`
                              : ``
                            : ""}
                        </span>{" "}
                      </div> */}
                        </div>
                      );
                    })}
                  </>
                )}

                <div className="flex justify-end items-center my-5">
                  <span className="inline-block ">
                    <Pagination
                      simple
                      size="small"
                      total={total}
                      pageSize={PAGESIZE}
                      current={page}
                      showSizeChanger={false}
                      onChange={(page) => {
                        setPage(page);
                      }}
                    />
                  </span>
                </div>
              </div>

              <Drawer
                width="100%"
                visible={detailsVisible}
                className="body-no-padding"
                onClose={() => {
                  setDetailsVisible(false);
                  setDetailData([]);
                  setDetailsPage(1);
                  setDetailsTotal(0);
                }}
                closable={false}
              >
                <Layout>
                  <Spin spinning={false}>
                    <NavHeader
                      suffix={
                        <span className="inline-block text-base text-right font-semibold">
                          {/* 币种盈利详情{" "}
                          <MediaQuery maxWidth={440}>
                            <br />
                          </MediaQuery> */}
                          {t("coinAll", sourceKey.highFrequency)}
                        </span>
                      }
                      showBack={true}
                      onBack={() => {
                        setDetailsVisible(false);
                        setDetailData([]);
                        setDetailsPage(1);
                        setDetailsTotal(0);
                      }}
                    >
                      <div className="rounded-xl bg-white box-shadow-center mx-1 py-3 px-2 my-5 ">
                        <div className="flex justify-between">
                          <span className="inline-block font-semibold">
                            {get(data, "_id.symbol")}
                          </span>
                          <span className="inline-block flex font-semibold">
                            Total :{" "}
                            {formatDecimalNumber(profitTotal, numberDecimal)}
                          </span>
                        </div>

                        {isEmpty(detailData) ? (
                          ""
                        ) : (
                          <>
                            {map(detailData, (item, index) => {
                              return (
                                <div
                                  className=" rounded-3xl p-3 my-3 flex justify-around items-center border"
                                  key={index}
                                >
                                  <span className="inline-block mr-4">
                                    {get(item, "binanceStocks.transactionTime")
                                      ? moment(
                                        get(
                                          item,
                                          "binanceStocks.transactionTime"
                                        )
                                      ).format("DD/MM/YYYY")
                                      : ""}
                                  </span>
                                  <span className="inline-block mr-4">
                                    {get(item, "binanceStocks.transactionTime")
                                      ? moment(
                                        get(
                                          item,
                                          "binanceStocks.transactionTime"
                                        )
                                      ).format("hh:mm:ss A")
                                      : ""}
                                  </span>
                                  <span className="inline-block mr-4">
                                    {formatDecimalNumber(
                                      get(item, "binanceStocks.profit"),
                                      numberDecimal
                                    )}
                                  </span>
                                </div>
                              );
                            })}
                          </>
                        )}

                        <div className="flex justify-end items-center my-5">
                          <span className="inline-block ">
                            <Pagination
                              simple
                              size="small"
                              total={detailsTotal}
                              pageSize={PAGESIZE}
                              current={detailsPage}
                              showSizeChanger={false}
                              onChange={(page) => {
                                setDetailsPage(page);
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </NavHeader>
                  </Spin>
                </Layout>
              </Drawer>
            </NavHeader>
          </Spin>
        </Layout>
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  product: state.product,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyProfitReportDrawer);
